import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './features.css';
import { Spinner, Input, Button, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Tab, Tabs, ListGroup, Container } from "react-bootstrap";
import moment from 'moment';
import feature_img_1 from '../../images/features_images/feature_img_1.png';
import feature_img_2 from '../../images/features_images/feature_img_2.png';
import icon_features_purchased from '../../images/features_images/icon_features_purchased.png';
import { GetFeaturesByOrg, CreatePaymentSession, CheckPaymentStatus } from '../helpers/apis';
import { hasValue } from '../helpers/common_functions';
import { FaArrowLeft, FaUser } from "react-icons/fa";
//import { useSearchParams } from "react-router-dom";

const Features = ({ userInfo, selectedOrg, resetFlag }) => {
    const { orgGuid } = useParams();
    const [selected_feature, setSelected_feature] = useState(null);
    const [payment_feature, setPayment_feature] = useState(null);
    const [all_features, setFeatures] = useState(null);
    const [devices, setDevices] = useState([]);
    const [device_features, setdevice_features] = useState([]);
    const [new_purchase_devices, Setnew_purchase_devices] = useState([]);

    const GetData = async () => {
        //let obj = JSON.parse(localStorage.getItem('auth'));
        let resp = await GetFeaturesByOrg(orgGuid);
        setFeatures(resp.data)

        let dev = resp.data?.device_features
        const allFeaturePurchases = dev.flatMap(a => a.feature_purchase);

        setDevices(dev)
        setdevice_features(allFeaturePurchases)
    }

    useEffect(() => {
        GetData();
    }, [resetFlag]);


    //#region Feature description with Tabs for All features & My Addons

    const FeatureImage = ({ imageURL }) => {
        return (
            <>
                {imageURL === "feature_img_1" && <img src={feature_img_1} alt="Icon" className="me-3" />}
                {imageURL === "feature_img_2" && <img src={feature_img_2} alt="Icon" className="me-3" />}
            </>)
    }

    const UserImage = ({ profilePicture }) => {
        return (
            <>
                {hasValue(profilePicture) ?
                    <img src={`data:image/png;base64, ${profilePicture}`} alt="Profile" className="rounded-circle m-2" width="48" />
                    :
                    <FaUser size={36} class=" m-3" />
                }
            </>)
    }

    const FeatureDetails = ({ feature }) => {
        let isPurchased = device_features.length > 0 && device_features.filter(a => a.featureId === feature.id).length > 0 ? true : false;

        return (
            <>
                <ListGroup className="cursor_pointer w-100 mt-3 mb-3" onClick={(e) => { e.preventDefault(); setSelected_feature(feature); }}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className={"d-flex align-items-center"}>
                            {hasValue(feature?.imageURL) &&
                                <FeatureImage imageURL={feature.imageURL} />
                            }
                            <div>
                                {feature.featureName}
                                <p className="mb-0 text-muted feature_desc">
                                    {feature.featureDesc}
                                </p>
                            </div>
                        </div>
                        {isPurchased &&
                            <>
                                <div class="float_right">
                                    <img src={icon_features_purchased} alt="Icon" className="ms-3" />
                                </div>
                            </>
                        }
                    </div>
                </ListGroup>
                <hr />
            </>
        )
    }

    //#endregion

    const DeviceFeatureDetails = ({ device_feature }) => {

        let find_features = all_features.features.flatMap(a => a.features);
        let feature = find_features.find(a => a.id === device_feature.featureId);

        return (
            <>
                <ListGroup className="w-100 mt-3 mb-3">
                    <div className={"d-flex align-items-center"}>
                        {hasValue(feature?.imageURL) &&
                            <FeatureImage imageURL={feature.imageURL} />
                        }
                        <div>
                            {feature.featureName}
                            <p className="mb-0 text-muted feature_desc">
                                {feature.featureDesc}
                            </p>
                        </div>
                    </div>
                    <div className="mt-3 text-muted feature_desc" style={{ textAlign: "right" }}>
                        ${feature.price}/month renews on {moment(new Date(device_feature.validTo)).format('DD-MM-YYYY')}
                    </div>
                </ListGroup>
                <hr />
            </>
        )
    }

    const SelFeatureDesc = ({ feature }) => {
        return (
            <>
                <ListGroup className="w-100 p-2">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className={"d-flex align-items-center"}>
                            {hasValue(feature?.imageURL) &&
                                <FeatureImage imageURL={feature.imageURL} />
                            }
                            <div>
                                <h4>
                                    {feature.featureName}
                                </h4>
                                <div className="mt-1 text-muted feature_desc">
                                    ${feature.price}/month per device
                                </div>
                            </div>
                        </div>
                    </div>
                </ListGroup>
                <hr />
                <h5 className="ms-2">
                    Description
                </h5>
                <hr />
                <div className="p-2 text-muted feature_desc">
                    {feature.featureDesc}
                </div>
                <hr />
            </>
        )
    }

    const SelectedFeatureView = () => {

        const sel_feature_purchase = all_features.device_features.filter(device =>
            device.feature_purchase.some(fp => fp.featureId === selected_feature.id)
        );

        return (
            <>
                <SelFeatureDesc feature={selected_feature} />
                <h5 className="ms-2">
                    Devices
                </h5>
                <hr />
                {sel_feature_purchase.length === 0 ?
                    <div className="p-2 text-muted feature_desc">
                        This add-on is not currently enabled on any of your devices.
                    </div>
                    :
                    <>
                        {sel_feature_purchase.map((device) =>
                            <ListGroup className="w-100 mt-3 mb-3">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className={"d-flex align-items-center"}>
                                        <UserImage profilePicture={device?.profilePicture} />
                                        <div>
                                            <h5 className="fw-bold">{device?.name || 'Device'}</h5>
                                            <p className="mb-0 text-muted feature_desc">
                                                <strong>Care</strong><i>Window</i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ListGroup>
                        )}
                    </>
                }
                <div class="row">
                    <div className="col-md-2">&nbsp;</div>
                    <div className="col-md-8">
                        <div class="m-2">
                            <Button style={{ width: "100%" }} color="primary" onClick={(e) => { e.preventDefault(); setPayment_feature(selected_feature); setSelected_feature(null); }}>Purchase add-on - ${selected_feature.price}/month</Button>
                            <div className="p-2 text-muted feature_desc text" style={{ textAlign: "center" }}>
                                You'll be asked to confirm your purchase.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">&nbsp;</div>
                </div>
            </>
        )
    }

    const [popup_new_purchase_devices, setpopup_new_purchase_devices] = useState([]);
    const [openSelectDeviceModal, setopenSelectDeviceModal] = useState(false);

    const PaymentFeatureView = () => {
        const not_feature_purchase = all_features.device_features.filter(device => !device.feature_purchase.some(fp => fp.featureId === payment_feature.id));

        const AddDeviceToPurchase = (device, value) => {
            setpopup_new_purchase_devices((prevDevices) => {
                if (value === true) {
                    return [...prevDevices, device];
                } else {
                    return prevDevices.filter(id => id !== device);
                }
            });

            setopenSelectDeviceModal(true);
        }

        const CompletePaymrent = async () => {
            let obj = JSON.parse(localStorage.getItem('auth'));

            try {
                let post_data = { initiatorUserId: userInfo.userId, paymentDeviceIds: new_purchase_devices, featureId: payment_feature.id, orgId: orgGuid }

                let resp = await CreatePaymentSession(post_data);

                if (resp.data.type === "stripe") {
                    window.location.href = resp.data.url;
                }
                else {
                    let subscription_id = resp.data.id;

                    const link = document.createElement('a');
                    link.href = resp.data.url;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    setInterval(async () => { // check payment is received
                        let post_data = { subscriptionId: subscription_id, paymentDeviceIds: new_purchase_devices, featureId: payment_feature.id }
                        let resp = await CheckPaymentStatus(post_data);

                        if (resp.data.value === true) {
                            window.location.reload();
                        }

                    }, 2000)
                }
            }
            catch (error) {
                alert("We can not process your request. Please contact support.");
                setSelected_feature(null);
                setPayment_feature(null);
            }
        }

        return (
            <>
                <SelFeatureDesc feature={payment_feature} />
                <h5 className="ms-2">
                    Select Devices
                </h5>
                <hr />
                <ListGroup className="w-100 mt-3 mb-3">
                    {new_purchase_devices === null || new_purchase_devices.length === 0 ?
                        <div className="mb-2 p-2 text-muted feature_desc text_center">
                            0 Devices Selected
                        </div> : null}

                    {not_feature_purchase.map((device, index) =>
                        new_purchase_devices !== null && new_purchase_devices.length > 0 && new_purchase_devices.includes(device.id) ?
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className={"d-flex align-items-center"}>
                                    <UserImage profilePicture={device?.profilePicture} />
                                    <div>
                                        <h5 className="fw-bold">{device?.name || 'Device'}</h5>
                                        <p className="mb-0 text-muted feature_desc">
                                            <strong>Care</strong><i>Window</i>
                                        </p>
                                    </div>
                                </div>
                            </div> : null
                    )}

                    <div class="row">
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-6">
                            <Button style={{ width: "100%" }} color="primary" onClick={(e) => { e.preventDefault(); setopenSelectDeviceModal(true); }}>Select Devices</Button>
                        </div>
                        <div className="col-md-3">&nbsp;</div>
                    </div>

                </ListGroup>
                {/*not_feature_purchase.map((device, index) =>
                    <ListGroup className="w-100 mt-3 mb-3">
                        {index === 0 &&
                            <>
                                <div className="mb-2 p-2 text-muted feature_desc text">
                                    Which device do you want to use this add-on whith?
                                </div>
                            </>
                        }
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className={"d-flex align-items-center"}>
                                <UserImage profilePicture={device?.profilePicture} />
                                <div>
                                    <h5 className="fw-bold">{device?.name || 'Device'}</h5>
                                    <p className="mb-0 text-muted feature_desc">
                                        <strong>Care</strong><i>Window</i>
                                    </p>
                                </div>
                            </div>
                            <div class="float_right">
                                <Input type="checkbox" name="purchase_device" onChange={(e) => { e.preventDefault(); AddDeviceToPurchase(device.id, e.target.checked); }}
                                    checked={new_purchase_devices !== null && new_purchase_devices.length > 0 && new_purchase_devices.includes(device.id) ? true : false}
                                    className="me-4" />
                            </div>
                        </div>
                    </ListGroup>
                )*/}
                <hr />
                <Row className="p-2">
                    <Col>Subscription</Col>
                    <Col className="text-end">${payment_feature.price}/month</Col>
                </Row>
                <Row className="p-2">
                    <Col>First billing date</Col>
                    <Col className="text-end">{moment(new Date()).format('DD-MM-YYYY')}</Col>
                </Row>
                <Row className="p-2">
                    <Col><strong>Due Now</strong></Col>
                    <Col className="text-end">
                        <strong>
                            ${new_purchase_devices === null || new_purchase_devices.length === 0 ? "0.00" : (new_purchase_devices.length * payment_feature.price)}/month</strong> </Col>
                </Row>
                <div class="m-2">
                    <div class="row">
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-6">
                            <Button style={{ width: "100%" }} color="primary"
                                disabled={new_purchase_devices === null || new_purchase_devices.length === 0}
                                onClick={(e) => { e.preventDefault(); CompletePaymrent(); }}>Complete purchase</Button>
                        </div>
                        <div className="col-md-3">&nbsp;</div>
                    </div>
                </div>

                {openSelectDeviceModal && (
                    <div className="modal" tabIndex={"-1"} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content" style={{ height: "98vh", minWidth: "700px" }} >
                                <div className="modal-header">
                                    <h1 className="modal-title">Select Devices</h1>
                                    <button className="close-button" onClick={() => setopenSelectDeviceModal(false)} >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <table className="table table-hover table-wrap" style={{ margin: "40px 0" }} >
                                        <thead>
                                            <tr>
                                                <th>Device Name</th>
                                                <th>Device S/N</th>
                                                <th>Select</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {not_feature_purchase.map((device) => (
                                                <tr key={device.id}>
                                                    <td>{device.name}</td>
                                                    <td>{device.serialNo}</td>
                                                    <td>
                                                        <Input type="checkbox" name="purchase_device" onChange={(e) => { e.preventDefault(); AddDeviceToPurchase(device.id, e.target.checked); }}
                                                            checked={popup_new_purchase_devices !== null && popup_new_purchase_devices.length > 0 && popup_new_purchase_devices.includes(device.id) ? true : false}
                                                            className="me-4" />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setopenSelectDeviceModal(false)}>
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={() => { Setnew_purchase_devices(popup_new_purchase_devices); setopenSelectDeviceModal(false); }}>
                                        Add Devices
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            <div className="midde_cont">
                <div className="container-fluid" style={{ paddingLeft: "0", paddingRight: "0" }} >
                    <div className="row column_title">
                        <div className="col-md-12">
                            <div className="page_title" style={{ display: "flex", alignItems: "center" }} >
                                <h2 style={{ flex: "1" }}>Add-on Store</h2>
                            </div>
                            <div class="white_shd full margin_bottom_30 pb-5 pt-4">
                                <div class="row">
                                    <div className="col-md-2">&nbsp;</div>
                                    <div className="col-md-8" id="divFeaturesPage">
                                        {all_features !== null ?
                                            <>
                                                {selected_feature === null && payment_feature === null &&
                                                    <>
                                                        <Tabs defaultActiveKey="all" className="mt-3 px-3">
                                                            <Tab eventKey="all" title="All Add-ons">
                                                                <Container className="mt-3">
                                                                    {all_features.features.slice().sort((a, b) => a.displayOrder - b.displayOrder).map((category) =>
                                                                        <>
                                                                            {category.features.length > 0 &&
                                                                                <>
                                                                                    <h5 className="fw-bold">{category.categoryName}</h5>
                                                                                    <hr />
                                                                                    {category.features.slice().sort((a, b) => a.displayOrder - b.displayOrder).map((el, index) =>
                                                                                        <>
                                                                                            <FeatureDetails feature={el} />
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </Container>
                                                            </Tab>
                                                            <Tab eventKey="my-addons" title="My Add-ons">
                                                                <Container className="mt-3">
                                                                    {devices.map((d) =>
                                                                        <>
                                                                            <h5 className="fw-bold">{d?.name || 'Device'}</h5>
                                                                            {d.feature_purchase.length === 0 &&
                                                                                <>
                                                                                </>
                                                                            }
                                                                            <hr />
                                                                            {d.feature_purchase.map((el, index) =>
                                                                                <>
                                                                                    <DeviceFeatureDetails device_feature={el} />
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Container>
                                                            </Tab>
                                                        </Tabs>
                                                    </>
                                                }

                                                {selected_feature !== null &&
                                                    <>
                                                        <div class="ms-3 mt-3 mb-3 cursor_pointer" onClick={(e) => { e.preventDefault(); setSelected_feature(null); Setnew_purchase_devices([]); }}>
                                                            <FaArrowLeft size={18} />&nbsp;&nbsp;Back
                                                        </div>
                                                        <div class="feature_top_desc p-3">
                                                            <SelectedFeatureView />
                                                        </div>
                                                    </>
                                                }

                                                {payment_feature !== null &&
                                                    <>
                                                        <div class="ms-3 mt-3 mb-3 cursor_pointer" onClick={(e) => { e.preventDefault(); setSelected_feature(payment_feature); setPayment_feature(null); Setnew_purchase_devices([]); }}>
                                                            <FaArrowLeft size={18} />&nbsp;&nbsp;Back
                                                        </div>
                                                        <div class="feature_top_desc p-3">
                                                            <PaymentFeatureView />
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            : <Spinner color="primary" />}

                                    </div>
                                    <div className="col-md-2">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Features;