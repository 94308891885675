import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { format, parseISO } from "date-fns";
import { generateRandomString } from "../header";
import toast, { Toaster } from "react-hot-toast";
import PaginatedDeviceTable from "./PaginatedDeviceTable";
import AgoraVideoCall from "../AgoraVideoCall";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  KeyboardVoiceRounded,
  MicOffRounded,
  VideocamRounded,
  VideocamOffRounded,
} from "@mui/icons-material";

function EventCalendar({ userInfo, org }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [devices, setDevices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [showEventActions, setShowEventActions] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewVideoTrack, setPreviewVideoTrack] = useState(null);
  const [previewAudioTrack, setPreviewAudioTrack] = useState(null);
  const [isPreviewCameraEnabled, setIsPreviewCameraEnabled] = useState(true);
  const [isPreviewMicrophoneEnabled, setIsPreviewMicrophoneEnabled] =
    useState(true);
  const previewVideoRef = useRef(null);

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedAudioInput, setSelectedAudioInput] = useState("");
  const [selectedAudioOutput, setSelectedAudioOutput] = useState("");
  const [selectedCamera, setSelectedCamera] = useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isJoiningEvent, setIsJoiningEvent] = useState(false);
  const [selectedGuestTab, setSelectedGuestTab] = useState("CareWindowUsers");
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [filterByInvitedUsers, setFilterByInvitedUsers] = useState(true);
  const [isLoadingFamilyMembers, setIsLoadingFamilyMembers] = useState(false);
  const [showSaveRequiredModal, setShowSaveRequiredModal] = useState(false);
  const [linkExpiry, setLinkExpiry] = useState("24");
  const [shareableLink, setShareableLink] = useState("");
  const [shareableLinkLoading, setShareableLinkLoading] = useState(false);

  useEffect(() => {
    if (selectedGuestTab === "FamilyMembers") {
      if (selectedDevices.length === 0 && filterByInvitedUsers) {
        setFamilyMembers([]);
      } else {
        fetchFamilyMembers();
      }
    }
  }, [selectedGuestTab, selectedDevices, filterByInvitedUsers]);

  useEffect(() => {
    if (!showModal) {
      setFamilyMembers([]);
      setSelectedFamilyMembers([]);
      setShareableLink("");
    }
  }, [showModal]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (org?.id) {
      fetchEvents();
    }
  }, [org]);

  useEffect(() => {
    if (org?.code) {
      fetchOrgDevices();
    }
  }, [org]);

  const handleTimeChange = (e, field) => {
    try {
      const { value } = e.target;
      if (!value) return;

      const [hours, minutes] = value.split(":").map(Number);
      const currentDate =
        field === "startTime"
          ? new Date(currentEvent.start)
          : new Date(currentEvent.end);

      currentDate.setHours(hours, minutes);

      if (field === "startTime") {
        const endDate = new Date(currentDate.getTime() + 60 * 60 * 1000);
        setCurrentEvent((prev) => ({
          ...prev,
          start: currentDate,
          end: endDate,
        }));
      } else {
        setCurrentEvent((prev) => ({
          ...prev,
          end: currentDate,
        }));
      }
    } catch (error) {
      console.error("Error handling time change:", error);
    }
  };

  const fetchFamilyMembers = async (filterState = filterByInvitedUsers) => {
    // If we're filtering by invited users and no users are selected, show nothing
    if (filterState && selectedDevices.length === 0) {
      setFamilyMembers([]);
      return;
    }

    setIsLoadingFamilyMembers(true);

    try {
      let allFamilyMembers = [];

      // If filtering by invited users, only fetch family members for selected devices
      if (filterState) {
        // Fetch family members for each selected device
        for (const deviceId of selectedDevices) {
          const response = await fetch(
            `${process.env.REACT_APP_DEVICE_API_URL}/linked_devices/${deviceId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                portalscope: "b3NMWOVzfdRUjrW",
                validatereq: generateRandomString(30),
              },
              credentials: "include",
            }
          );

          if (response.ok) {
            const data = await response.json();
            // Get parent device info from the devices array
            const parentDevice = devices.find((d) => d.id === deviceId);

            // Map the linked devices to include the parent device information
            const linkedDevicesWithParent = data
              .filter((device) => device.id !== deviceId) // Filter out the parent device itself
              .map((device) => ({
                ...device,
                parentDeviceName: parentDevice?.name || "Unknown CareWindow",
              }));

            allFamilyMembers = [
              ...allFamilyMembers,
              ...linkedDevicesWithParent,
            ];
          }
        }
      } else {
        // If not filtering, fetch family members for all devices
        for (const device of devices) {
          const response = await fetch(
            `${process.env.REACT_APP_DEVICE_API_URL}/linked_devices/${device.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                portalscope: "b3NMWOVzfdRUjrW",
                validatereq: generateRandomString(30),
              },
              credentials: "include",
            }
          );

          if (response.ok) {
            const data = await response.json();
            const linkedDevicesWithParent = data
              .filter((linkedDevice) => linkedDevice.id !== device.id)
              .map((linkedDevice) => ({
                ...linkedDevice,
                parentDeviceName: device.name,
              }));

            allFamilyMembers = [
              ...allFamilyMembers,
              ...linkedDevicesWithParent,
            ];
          }
        }
      }

      // Remove duplicates by ID
      const uniqueFamilyMembers = allFamilyMembers.filter(
        (member, index, self) =>
          index === self.findIndex((m) => m.id === member.id)
      );

      setFamilyMembers(uniqueFamilyMembers);
    } catch (error) {
      console.log("Error fetching family members:", error);
    } finally {
      setIsLoadingFamilyMembers(false);
    }
  };

  const handleFamilyMemberSelect = (memberId) => {
    setSelectedFamilyMembers((prev) => {
      if (prev.includes(memberId)) {
        return prev.filter((id) => id !== memberId);
      } else {
        return [...prev, memberId];
      }
    });
  };

  const handleFilterToggle = () => {
    // First update the filter state
    setFilterByInvitedUsers((prevState) => !prevState);

    // Then fetch family members based on the NEW filter state
    // (using the callback form of setState to ensure we have the latest state)
    setFilterByInvitedUsers((newFilterState) => {
      // Use setTimeout to ensure this runs after state is updated
      setTimeout(() => {
        fetchFamilyMembers(newFilterState);
      }, 0);
      return newFilterState;
    });
  };

  const fetchOrgDevices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/get_org_devices?OrgCode=${org.code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();

        setDevices(data);
      } else {
        console.log("Failed to fetch devices.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getEventStatus = (eventStart, eventEnd, autoStart) => {
    const now = new Date();
    const startTime = new Date(eventStart);
    const endTime = new Date(eventEnd);

    if (now < startTime) {
      return "upcoming";
    } else if (now >= startTime && now <= endTime) {
      return autoStart ? "inProgress" : "upcoming";
    } else {
      return "ended";
    }
  };

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_EVENT_API_URL}/org/${org.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch events");
      }

      const data = await response.json();
      const formattedEvents = data.map((item) => {
        const startTime = new Date(`${item.e.startTime}Z`);
        const endTime = new Date(`${item.e.endTime}Z`);
        const status = getEventStatus(startTime, endTime, item.e.autoStart);

        let backgroundColor;
        switch (status) {
          case "upcoming":
            backgroundColor = "#1976D2";
            break;
          case "inProgress":
            backgroundColor = "#DC3545";
            break;
          case "ended":
            backgroundColor = "#212529";
            break;
          default:
            backgroundColor = "#1976D2";
        }

        return {
          id: item.e.id,
          title: item.e.eventName,
          start: startTime,
          end: endTime,
          autoStart: item.e.autoStart,
          description: item.e.eventDesc,
          selectedDevices: item.device || [],
          backgroundColor,
        };
      });

      console.log("Formatted events:", formattedEvents);
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateSelect = (selectInfo) => {
    const startDate = selectInfo.start;
    setCurrentEvent({
      start: startDate,
      end: startDate,
      title: "",
      extendedProps: {
        description: "",
        location: "",
      },
    });
    setShowModal(true);
  };

  const handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();
    const eventId = clickInfo.event.id;
    const eventStart = new Date(clickInfo.event.start);
    const eventEnd = new Date(clickInfo.event.end);
    const eventData = events.find((e) => e.id === eventId);
    const status = getEventStatus(eventStart, eventEnd, eventData?.autoStart);

    setSelectedEventId(eventId);

    // Calculate position for dropdown
    const rect = clickInfo.el.getBoundingClientRect();
    setDropdownPosition({
      x: rect.left,
      y: rect.bottom,
    });

    setIsJoiningEvent(status === "inProgress");
    setShowDropdown(true);
  };

  const handleEditEvent = (eventId) => {
    setShareableLink("");
    const eventData = events.find((event) => event.id === eventId);
    if (eventData) {
      const deviceIds = eventData.selectedDevices.map((device) => device.id);
      setSelectedDevices(deviceIds);
      setCurrentEvent({
        id: eventData.id,
        start: eventData.start,
        end: eventData.end,
        title: eventData.title,
        autoStart: eventData.autoStart,
        selectedDevices: eventData.selectedDevices,
      });
      setShowModal(true);
      setSelectedGuestTab("CareWindowUsers");
    }
    setShowDropdown(false);
  };

  const handleStartEvent = async (eventId) => {
    try {
      const event = events.find((e) => e.id === eventId);
      if (!event) {
        throw new Error("Event not found");
      }

      setShowPreviewModal(true);
      setIsPreviewModalOpen(true);
      setIsPreviewCameraEnabled(true);
      setIsPreviewMicrophoneEnabled(true);

      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        const audioDevices = await AgoraRTC.getMicrophones();
        const audioOutput = await AgoraRTC.getPlaybackDevices();
        const cameraDevices = await AgoraRTC.getCameras();

        setAudioInputDevices(filterDevices(audioDevices));
        setAudioOutputDevices(filterDevices(audioOutput));
        setVideoDevices(filterDevices(cameraDevices));

        let audioTrack = await AgoraRTC.createMicrophoneAudioTrack(
          selectedAudioInput ? { microphoneId: selectedAudioInput } : undefined
        );
        let videoTrack = await AgoraRTC.createCameraVideoTrack(
          selectedCamera
            ? {
                encoderConfig: {
                  width: 640,
                  height: 400,
                  frameRate: 25,
                  bitrate: 400,
                },
                cameraId: selectedCamera,
              }
            : undefined
        );

        setPreviewAudioTrack(audioTrack);
        setPreviewVideoTrack(videoTrack);
        if (previewVideoRef.current) {
          videoTrack.play(previewVideoRef.current);
        }
      } catch (error) {
        console.error("Failed to create preview tracks", error);
        setIsPreviewCameraEnabled(false);
        setIsPreviewMicrophoneEnabled(false);
      }
    } catch (error) {
      console.error("Error starting event:", error);
      toast.error("Failed to start event");
    }
  };

  const cleanupPreviewTracks = async () => {
    if (previewVideoTrack) {
      previewVideoTrack.stop();
      previewVideoTrack.close();
      setPreviewVideoTrack(null);
    }
    if (previewAudioTrack) {
      previewAudioTrack.stop();
      previewAudioTrack.close();
      setPreviewAudioTrack(null);
    }
  };

  const handleStartCall = async () => {
    setShowPreviewModal(false);
    await cleanupPreviewTracks();

    const callData = {
      userInfo,
      eventId: selectedEventId,
      callerName: userInfo?.name || "Admin",
      initialCameraEnabled: isPreviewCameraEnabled,
      initialMicrophoneEnabled: isPreviewMicrophoneEnabled,
      initialAudio: selectedAudioInput,
      initialVideo: selectedCamera,
      initialAudioOutput: selectedAudioOutput,
      isJoiningEvent: isJoiningEvent,
    };

    const encodedCallData = btoa(JSON.stringify(callData));
    const videoCallTab = window.open(
      `/video-call?data=${encodedCallData}&type=event`,
      "_blank"
    );

    if (videoCallTab) {
      videoCallTab.focus();
    } else {
      toast.error(
        "Unable to open video call tab. Please check your popup blocker settings."
      );
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrentEvent(null);
    setSelectedDevices([]);
    setSelectedFamilyMembers([]);
    setShareableLink("");
    setSelectedGuestTab("CareWindowUsers");
    setSearchQuery("");
    setFilterByInvitedUsers(true);
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();

    try {
      let eventResponse;
      let eventId;

      const startTimeStr = new Date(currentEvent.start)
        .toISOString()
        .slice(0, -8);
      const endTimeStr = new Date(currentEvent.end).toISOString().slice(0, -8);

      if (currentEvent.id) {
        // Update existing event
        const updateData = {
          id: currentEvent.id,
          userId: userInfo.userId,
          organizationId: org.id,
          eventName: currentEvent.title,
          eventDesc: currentEvent.title,
          createdAt:
            currentEvent.createdAt || new Date().toISOString().slice(0, -1),
          updatedAt: new Date().toISOString().slice(0, -1),
          deletedAt: null,
          startTime: startTimeStr,
          endTime: endTimeStr,
          autoStart: currentEvent.autoStart || false,
        };

        eventResponse = await fetch(`${process.env.REACT_APP_EVENT_API_URL}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
          },
          credentials: "include",
          body: JSON.stringify(updateData),
        });

        eventId = currentEvent.id;
      } else {
        // Create new event
        const createData = {
          userId: userInfo.userId,
          organizationId: org.id,
          eventName: currentEvent.title,
          eventDesc: currentEvent.title,
          createdAt: new Date().toISOString().slice(0, -1),
          updatedAt: new Date().toISOString().slice(0, -1),
          deletedAt: null,
          startTime: startTimeStr,
          endTime: endTimeStr,
          autoStart: currentEvent.autoStart || false,
        };

        eventResponse = await fetch(`${process.env.REACT_APP_EVENT_API_URL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
          },
          credentials: "include",
          body: JSON.stringify(createData),
        });

        if (!eventResponse.ok) {
          throw new Error("Failed to create event");
        }

        const eventData = await eventResponse.json();
        eventId = eventData.id;
      }

      // Link devices to event
      if (selectedDevices.length > 0) {
        const linkDevicesResponse = await fetch(
          `${process.env.REACT_APP_EVENT_API_URL}/LinkDevicesToEvent`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              portalscope: "b3NMWOVzfdRUjrW",
              validatereq: generateRandomString(30),
            },
            credentials: "include",
            body: JSON.stringify({
              eventId: eventId,
              deviceId: selectedDevices,
            }),
          }
        );

        if (!linkDevicesResponse.ok) {
          throw new Error("Failed to link devices to event");
        }

        // Show success toast message
        toast.success("Event successfully created!");
      }

      // Refresh events list
      await fetchEvents();
      handleModalClose();
    } catch (error) {
      console.error("Error saving event:", error);
      setError(error.message);
    }
  };

  const handleCreateEventClick = () => {
    setShareableLink("");
    setFilterByInvitedUsers(true);

    const now = new Date();
    const endTime = new Date(now.getTime() + 60 * 60 * 1000);
    setCurrentEvent({
      start: now,
      end: endTime,
      title: "",
      autoStart: false,
      selectedDevices: [],
    });
    setSelectedDevices([]);
    setSearchQuery("");
    setSelectedGuestTab("CareWindowUsers");
    setShowModal(true);
  };

  const handleEventInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setCurrentEvent((prev) => ({ ...prev, title: value }));
    } else if (name === "start") {
      // If value is empty (cleared), set to current date
      if (!value) {
        const now = new Date();
        setCurrentEvent((prev) => ({
          ...prev,
          start: now,
          end: new Date(now.getTime() + 60 * 60 * 1000),
        }));
        return;
      }

      try {
        const prevDate = currentEvent?.start
          ? new Date(currentEvent.start)
          : new Date();
        const newDate = new Date(value);

        // Validate if newDate is valid
        if (isNaN(newDate.getTime())) {
          console.error("Invalid date value");
          return;
        }

        newDate.setHours(prevDate.getHours(), prevDate.getMinutes());
        setCurrentEvent((prev) => ({
          ...prev,
          start: newDate,
          end: new Date(newDate.getTime() + 60 * 60 * 1000),
        }));
      } catch (error) {
        console.error("Error handling date change:", error);
      }
    }
  };

  const handleDeleteEvent = async () => {
    if (!currentEvent || !currentEvent.id) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_EVENT_API_URL}/${currentEvent.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete event");
      }

      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== currentEvent.id)
      );
      handleModalClose();
      toast.success("Event deleted !");
    } catch (error) {
      console.error("Error deleting event:", error);
      setError(error.message);
    }
  };

  const handleGuestSearch = (searchTerm) => {
    setSearchQuery(searchTerm || "");
  };

  const handleDeviceSelect = (deviceId) => {
    console.log("Toggling device:", deviceId);
    console.log("Current selected devices:", selectedDevices);

    setSelectedDevices((prev) => {
      const newSelection = prev.includes(deviceId)
        ? prev.filter((id) => id !== deviceId)
        : [...prev, deviceId];
      console.log("New selection:", newSelection);
      return newSelection;
    });
  };

  const filteredDevices = devices.filter((device) => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase().trim();
    return (
      device.name?.toLowerCase().includes(query) ||
      false ||
      device.serialNo?.toLowerCase().includes(query) ||
      false
    );
  });

  const eventDidMount = (info) => {
    // 添加鼠标悬停效果
    info.el.style.cursor = "pointer";
    info.el.style.transition = "background-color 0.2s ease";

    // 添加悬停效果的事件监听器
    const addHoverEffect = () => {
      info.el.style.backgroundColor = "#f0f0f0";
      info.el.style.transform = "scale(1.02)";
    };

    const removeHoverEffect = () => {
      info.el.style.backgroundColor = "";
      info.el.style.transform = "scale(1)";
    };

    info.el.addEventListener("mouseenter", addHoverEffect);
    info.el.addEventListener("mouseleave", removeHoverEffect);
  };

  // 判断事件是否已经开始
  const isEventStarted = (eventStart) => {
    const now = new Date();
    return now > new Date(eventStart);
  };

  const filterDevices = (devices) => {
    return devices
      .filter(
        (device, index, self) =>
          index === self.findIndex((d) => d.groupId === device.groupId)
      )
      .map((device) => ({
        deviceId: device.deviceId,
        ...device,
        label: device.label
          .replace(/default|communications/gi, "")
          .replace(/-/g, "")
          .trim(),
      }));
  };

  const handleDeviceChange = (event, setter) => {
    setter(event.target.value);
  };

  const togglePreviewCamera = async () => {
    if (previewVideoTrack) {
      await previewVideoTrack.setEnabled(!isPreviewCameraEnabled);
      setIsPreviewCameraEnabled(!isPreviewCameraEnabled);
    }
  };

  const togglePreviewMicrophone = async () => {
    if (previewAudioTrack) {
      await previewAudioTrack.setEnabled(!isPreviewMicrophoneEnabled);
      setIsPreviewMicrophoneEnabled(!isPreviewMicrophoneEnabled);
    }
  };

  const buttonStyle = (enabled) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    margin: "0 10px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    backgroundColor: enabled ? "#1976D2" : "#1976D2",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    color: "white",
  });

  const handleGuestTabClick = (tabName) => {
    // If trying to access Shareable Link tab in create mode (no event ID), show save required modal
    if (tabName === "ShareableLink" && !currentEvent?.id) {
      setShowSaveRequiredModal(true);
      return;
    }

    setSelectedGuestTab(tabName);

    if (tabName === "ShareableLink" && currentEvent?.id) {
      setShareableLinkLoading(true);

      if (!shareableLink) {
        handleGenerateLink();
      } else {
        setShareableLinkLoading(false);
      }
    }

    if (tabName === "FamilyMembers") {
      fetchFamilyMembers();
    }
  };

  // Create a separate function for saving from the modal
  const handleSaveFromModal = async () => {
    try {
      // Close the Save Required modal first
      setShowSaveRequiredModal(false);

      // Get formatted times
      const startTimeStr = new Date(currentEvent.start)
        .toISOString()
        .slice(0, -8);
      const endTimeStr = new Date(currentEvent.end).toISOString().slice(0, -8);

      // Create new event
      const createData = {
        userId: userInfo.userId,
        organizationId: org.id,
        eventName: currentEvent.title,
        eventDesc: currentEvent.title,
        createdAt: new Date().toISOString().slice(0, -1),
        updatedAt: new Date().toISOString().slice(0, -1),
        deletedAt: null,
        startTime: startTimeStr,
        endTime: endTimeStr,
        autoStart: currentEvent.autoStart || false,
      };

      const eventResponse = await fetch(
        `${process.env.REACT_APP_EVENT_API_URL}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
          },
          credentials: "include",
          body: JSON.stringify(createData),
        }
      );

      if (!eventResponse.ok) {
        throw new Error("Failed to create event");
      }

      // Get the ID from the response
      const eventData = await eventResponse.json();
      const eventId = eventData.id;

      // Update currentEvent with the new ID
      setCurrentEvent((prev) => ({
        ...prev,
        id: eventId,
      }));

      // Link devices to event if any are selected
      if (selectedDevices.length > 0) {
        const linkDevicesResponse = await fetch(
          `${process.env.REACT_APP_EVENT_API_URL}/LinkDevicesToEvent`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              portalscope: "b3NMWOVzfdRUjrW",
              validatereq: generateRandomString(30),
            },
            credentials: "include",
            body: JSON.stringify({
              eventId: eventId,
              deviceId: selectedDevices,
            }),
          }
        );

        if (!linkDevicesResponse.ok) {
          console.error("Failed to link devices to event");
        }
      }

      // Link family members if any are selected
      if (selectedFamilyMembers.length > 0) {
        try {
          const linkFamilyResponse = await fetch(
            `${process.env.REACT_APP_EVENT_API_URL}/LinkFamilyMembersToEvent`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                portalscope: "b3NMWOVzfdRUjrW",
                validatereq: generateRandomString(30),
              },
              credentials: "include",
              body: JSON.stringify({
                eventId: eventId,
                familyMemberIds: selectedFamilyMembers,
              }),
            }
          );

          if (!linkFamilyResponse.ok) {
            console.error("Failed to link family members to event");
          }
        } catch (error) {
          console.error("Error linking family members:", error);
        }
      }

      // Show success message
      toast.success("Event saved successfully");

      // Refresh the events list
      await fetchEvents();

      // Switch to the Shareable Link tab
      setSelectedGuestTab("ShareableLink");
    } catch (error) {
      console.error("Error saving event from modal:", error);
      toast.error("Failed to save event");
    }
  };

  const handleGenerateLink = async () => {
    try {
      // Set loading state to true at the beginning
      setShareableLinkLoading(true);

      const requestBody = {
        EventId: currentEvent.id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/VideoCallInvite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
            accept: "text/plain",
          },
          body: JSON.stringify(requestBody),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setShareableLink(data.url);
        toast.success("Temporary link generated successfully!");
      } else {
        const errorData = await response.text();
        console.error("API Error:", errorData);
        toast.error("Failed to generate temporary link. Please try again.");
        // Clear the link on error
        setShareableLink("");
      }
    } catch (error) {
      console.error("Error generating temporary link:", error);
      toast.error("An error occurred while generating the temporary link.");
      // Clear the link on error
      setShareableLink("");
    } finally {
      // Always set loading to false when done, whether success or error
      setShareableLinkLoading(false);
    }
  };

  return (
    <div className="midde_cont">
      <div
        className="container-fluid"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="row column_title">
          <div className="col-md-12">
            <div className="page_title">
              <h2>Event Calendar</h2>
            </div>
          </div>
        </div>
        <div className="row column1">
          <div className="col-md-12">
            <div className="white_shd full margin_bottom_30 add_devices_instruction_list_wrap">
              <div style={{ marginBottom: "10px" }}>
                <button
                  className="btn btn-primary"
                  onClick={handleCreateEventClick}
                >
                  + Create Event
                </button>
              </div>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                editable={false}
                selectable={false}
                // selectMirror={true}
                // dayMaxEvents={true}
                events={events}
                select={handleDateSelect}
                eventClick={handleEventClick}
                eventDidMount={eventDidMount}
              />

              {showDropdown && (
                <div
                  ref={dropdownRef}
                  style={{
                    position: "fixed",
                    left: `${dropdownPosition.x}px`,
                    top: `${dropdownPosition.y}px`,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    padding: "8px 0",
                  }}
                >
                  <div
                    onClick={() => handleStartEvent(selectedEventId)}
                    style={{
                      padding: "8px 16px",
                      cursor: "pointer",
                      hover: {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#f5f5f5")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "white")
                    }
                  >
                    {(() => {
                      const selectedEvent = events.find(
                        (event) => event.id === selectedEventId
                      );
                      if (selectedEvent) {
                        const status = getEventStatus(
                          selectedEvent.start,
                          selectedEvent.end,
                          selectedEvent.autoStart
                        );
                        if (status === "ended") {
                          return "Start Event";
                        } else if (
                          status === "inProgress" &&
                          selectedEvent.autoStart
                        ) {
                          return "Join Event";
                        } else {
                          return "Start Event";
                        }
                      }
                      return "Start Event";
                    })()}
                  </div>
                  <div
                    onClick={() => handleEditEvent(selectedEventId)}
                    style={{
                      padding: "8px 16px",
                      cursor: "pointer",
                      hover: {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#f5f5f5")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "white")
                    }
                  >
                    Edit Event
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-lg"
            role="document"
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
              maxWidth: "900px",
            }}
          >
            <div
              className="modal-content"
              style={{
                width: "750px",
                maxWidth: "900px",
              }}
            >
              <div className="modal-header">
                <h1 className="modal-title">
                  {currentEvent?.id ? "Edit Event" : "Create Event"}
                </h1>
                <button
                  className="close-button"
                  style={{ top: "5px" }}
                  onClick={handleModalClose}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleEventSubmit}>
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    <div className="form-group mb-0" style={{ width: "70%" }}>
                      <label>Event Name</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={currentEvent?.title || ""}
                        onChange={handleEventInputChange}
                        required
                      />
                    </div>
                    <div
                      className="form-group mb-0 text-right"
                      style={{ width: "30%", paddingTop: "32px" }}
                    >
                      <label className="d-flex align-items-center justify-content-end">
                        <input
                          type="checkbox"
                          className="mr-2"
                          checked={currentEvent?.autoStart || false}
                          onChange={(e) =>
                            setCurrentEvent((prev) => ({
                              ...prev,
                              autoStart: e.target.checked,
                            }))
                          }
                        />
                        <span>Automatically start this event</span>
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Event Date</label>
                        <input
                          type="date"
                          name="start"
                          className="form-control"
                          value={
                            currentEvent?.start
                              ? format(
                                  new Date(currentEvent.start),
                                  "yyyy-MM-dd"
                                )
                              : ""
                          }
                          onChange={handleEventInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Start Time</label>
                        <input
                          type="time"
                          className="form-control"
                          value={
                            currentEvent?.start
                              ? format(new Date(currentEvent.start), "HH:mm")
                              : ""
                          }
                          onChange={(e) => handleTimeChange(e, "startTime")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>End Time</label>
                        <input
                          type="time"
                          className="form-control"
                          value={
                            currentEvent?.end
                              ? format(new Date(currentEvent.end), "HH:mm")
                              : ""
                          }
                          onChange={(e) => handleTimeChange(e, "endTime")}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Guests</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "50%" }}
                        placeholder="Search by CareWindow User or Serial"
                        value={searchQuery}
                        onChange={(e) => handleGuestSearch(e.target.value)}
                      />
                      {/* <span className="position-absolute" style={{ right: "10px", top: "50%", transform: "translateY(-50%)" }}>
                  🔍
                </span> */}
                    </div>
                  </div>

                  {/* Guest Tabs Section - Added for tab navigation similar to user settings */}
                  <div className="tab_style1">
                    <div
                      className="tabbar"
                      style={{
                        display: "block",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <nav>
                        <div
                          className="nav nav-tabs"
                          id="guest-nav-tab"
                          role="tablist"
                        >
                          <div
                            className={`nav-item nav-link ${
                              selectedGuestTab === "CareWindowUsers"
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleGuestTabClick("CareWindowUsers")
                            }
                          >
                            CareWindow Users
                          </div>
                          <div
                            className={`nav-item nav-link ${
                              selectedGuestTab === "FamilyMembers"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleGuestTabClick("FamilyMembers")}
                          >
                            Family Members
                          </div>
                          <div
                            className={`nav-item nav-link ${
                              selectedGuestTab === "ShareableLink"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleGuestTabClick("ShareableLink")}
                          >
                            Shareable Link
                          </div>
                        </div>
                      </nav>

                      <div className="tab-content" id="guest-nav-tabContent">
                        {/* CareWindow Users Tab - Original content */}
                        <div
                          className={`tab-pane fade ${
                            selectedGuestTab === "CareWindowUsers"
                              ? "show active"
                              : ""
                          }`}
                          id="guest-carewindow-users"
                          role="tabpanel"
                        >
                          <PaginatedDeviceTable
                            devices={filteredDevices}
                            selectedDevices={selectedDevices}
                            onDeviceSelect={handleDeviceSelect}
                            itemsPerPage={6}
                          />
                        </div>

                        {/* Family Members Tab with matching UI style */}
                        <div
                          className={`tab-pane fade ${
                            selectedGuestTab === "FamilyMembers"
                              ? "show active"
                              : ""
                          }`}
                          id="guest-family-members"
                          role="tabpanel"
                        >
                          <div className="mt-3 mb-3">
                            <div className="mb-3 d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="filter-by-invited"
                                className="mr-2"
                                checked={filterByInvitedUsers}
                                onChange={handleFilterToggle}
                                style={{ marginRight: "8px" }}
                              />
                              <label
                                htmlFor="filter-by-invited"
                                className="mb-0"
                              >
                                Filter by invited CareWindow Users
                              </label>
                            </div>

                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Family Member</th>
                                    <th>CareWindow User</th>
                                    <th>Selected</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoadingFamilyMembers ? (
                                    <tr>
                                      <td
                                        colSpan="3"
                                        className="text-center py-4"
                                      >
                                        <div
                                          className="spinner-border spinner-border-sm text-primary mr-2"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                        Loading family members...
                                      </td>
                                    </tr>
                                  ) : familyMembers.length > 0 ? (
                                    familyMembers.map((member) => (
                                      <tr key={member.id}>
                                        <td>
                                          {member.profilePicture ? (
                                            <img
                                              className="user-photo"
                                              src={`data:image/png;base64, ${member.profilePicture}`}
                                              alt={member.name}
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                marginRight: "10px",
                                              }}
                                            />
                                          ) : (
                                            <span
                                              className="user-icon"
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                marginRight: "10px",
                                                display: "inline-block",
                                                textAlign: "center",
                                                lineHeight: "30px",
                                                backgroundColor: "#eee",
                                              }}
                                            >
                                              {member.name
                                                ? member.name.charAt(0)
                                                : "?"}
                                            </span>
                                          )}
                                          {member.name}
                                        </td>
                                        <td>{member.parentDeviceName}</td>
                                        <td>
                                          {member.isEmailVerified === true ? (
                                            <input
                                              type="checkbox"
                                              checked={selectedFamilyMembers.includes(
                                                member.id
                                              )}
                                              onChange={() =>
                                                handleFamilyMemberSelect(
                                                  member.id
                                                )
                                              }
                                            />
                                          ) : (
                                            <span className="text-muted">
                                              No email address
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="3" className="text-center">
                                        {filterByInvitedUsers &&
                                        selectedDevices.length === 0
                                          ? "Please select CareWindow Users first"
                                          : "No family members available"}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* Shareable Link Tab */}
                        <div
                          className={`tab-pane fade ${
                            selectedGuestTab === "ShareableLink"
                              ? "show active"
                              : ""
                          }`}
                          id="guest-shareable-link"
                          role="tabpanel"
                        >
                          <div className="mt-3 mb-3">
                            <p>
                              Invite people outside of the organisation to this
                              event by sharing the link.
                            </p>

                            {shareableLinkLoading ? (
                              <div className="text-center py-4">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                                <p className="mt-2">
                                  Generating shareable link...
                                </p>
                              </div>
                            ) : shareableLink ? (
                              <div className="form-group mt-4">
                                <label
                                  htmlFor="shareableLink"
                                  style={{
                                    display: "block",
                                    marginBottom: "10px",
                                    fontWeight: "400",
                                    color: "black",
                                  }}
                                >
                                  Link
                                </label>
                                <div
                                  className="input-group"
                                  style={{ width: "60%" }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="shareableLink"
                                    value={shareableLink}
                                    readOnly
                                    style={{ marginRight: "10px" }}
                                  />
                                  <button
                                    className="btn btn-outline-dark"
                                    type="button"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        shareableLink
                                      );
                                      toast.success(
                                        "Link copied to clipboard!"
                                      );
                                    }}
                                    style={{ width: "100px" }}
                                  >
                                    Copy
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="alert alert-warning">
                                <p>
                                  Failed to generate link. Please try again.
                                </p>
                                <button
                                  className="btn btn-outline-primary mt-2"
                                  onClick={() => handleGenerateLink()}
                                >
                                  Retry
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    {currentEvent?.id && (
                      <button
                        type="button"
                        onClick={handleDeleteEvent}
                        className="btn btn-danger"
                        style={{ marginRight: "30rem" }}
                      >
                        Delete Event
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleModalClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        selectedDevices.length === 0 &&
                        selectedFamilyMembers.length === 0
                      }
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSaveRequiredModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Save Required</h5>
                <button
                  type="button"
                  className="close-button"
                  onClick={() => setShowSaveRequiredModal(false)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Generating the the link requires the event to be saved first.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowSaveRequiredModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveFromModal}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPreviewModal && (
        <div
          className="modal fade show"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.5)",
            overflowY: "auto", // 确保背景可滚动以防弹窗内容过高

            paddingBottom: "20px",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
            style={{
              maxWidth: "90%", // 根据需要调整宽度
              margin: "auto",
            }}
          >
            <div
              className="modal-content"
              style={{
                backgroundColor: "#f0f0f0",
                width: "100%",
                padding: "15px",
                maxHeight: "90vh", // 最大高度为视口高度的90%
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="modal-header" style={{ flexShrink: 0 }}>
                <h1 className="modal-title">Group Video Call Preview</h1>
                <button
                  type="button"
                  className="close-button"
                  onClick={() => {
                    setShowPreviewModal(false);
                    cleanupPreviewTracks();
                  }}
                  aria-label="Close"
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={{
                  padding: "15px",
                  overflowY: "auto", // 允许内容滚动
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    aspectRatio: "16/9",
                    backgroundColor: "black",
                    position: "relative",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    ref={previewVideoRef}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15px",
                  }}
                >
                  <button
                    onClick={togglePreviewMicrophone}
                    style={buttonStyle(isPreviewMicrophoneEnabled)}
                  >
                    {isPreviewMicrophoneEnabled ? (
                      <KeyboardVoiceRounded />
                    ) : (
                      <MicOffRounded />
                    )}
                  </button>
                  <button
                    onClick={togglePreviewCamera}
                    style={buttonStyle(isPreviewCameraEnabled)}
                  >
                    {isPreviewCameraEnabled ? (
                      <VideocamRounded />
                    ) : (
                      <VideocamOffRounded />
                    )}
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  {/* Microphone Selection */}
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "350px",
                      marginBottom: "10px",
                    }}
                  >
                    <label
                      htmlFor="audio-input-select"
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Microphone
                    </label>
                    <select
                      id="audio-input-select"
                      value={selectedAudioInput}
                      onChange={(e) =>
                        handleDeviceChange(e, setSelectedAudioInput)
                      }
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                      }}
                    >
                      <option value="">
                        System Default -{" "}
                        {audioInputDevices[0]?.label || "No Device"}
                      </option>
                      {audioInputDevices.map((device) => (
                        <option key={device.deviceId} value={device.deviceId}>
                          {device.label || "Unnamed Audio Input"}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Speaker Selection */}
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "350px",
                      marginBottom: "10px",
                    }}
                  >
                    <label
                      htmlFor="audio-output-select"
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Speaker
                    </label>
                    <select
                      id="audio-output-select"
                      value={selectedAudioOutput}
                      onChange={(e) =>
                        handleDeviceChange(e, setSelectedAudioOutput)
                      }
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                      }}
                    >
                      <option value="">
                        System Default -{" "}
                        {audioOutputDevices[0]?.label || "No Device"}
                      </option>
                      {audioOutputDevices.map((device) => (
                        <option key={device.deviceId} value={device.deviceId}>
                          {device.label || "Unnamed Audio Output"}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Camera Selection */}
                  <div style={{ width: "100%", maxWidth: "350px" }}>
                    <label
                      htmlFor="camera-select"
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Camera
                    </label>
                    <select
                      id="camera-select"
                      value={selectedCamera}
                      onChange={(e) => handleDeviceChange(e, setSelectedCamera)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                      }}
                    >
                      <option value="">
                        System Default - {videoDevices[0]?.label || "No Device"}
                      </option>
                      {videoDevices.map((device) => (
                        <option key={device.deviceId} value={device.deviceId}>
                          {device.label || "Unnamed Camera"}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="modal-footer"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexShrink: 0,
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setShowPreviewModal(false);
                    cleanupPreviewTracks();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleStartCall}
                >
                  Start Call
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default EventCalendar;
