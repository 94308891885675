import React, { useState, useEffect } from 'react'
//import '../css/bootstrap.min.css';
import '../style.css';
import '../css/responsive.css';
import '../css/perfect-scrollbar.css';
import '../css/custom.css';
import '../css/color_2.css';
import '../css/bootstrap-select.css';

import carewindow_blue from '../images/carewindow_blue.png'
import { Link } from 'react-router-dom'
import { useNavigate,useLocation } from 'react-router-dom'
import { generateRandomString } from './header';


// import Logincontextprovider from './logincontextprovider';

function Login() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const location = useLocation();


  useEffect(() => {
    
    const checkLoginStatus = async () => {
      const loggedin = localStorage.getItem('isLoggedIn') === 'true';
      if (loggedin) {

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/check-login-status`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            credentials: 'include',

          })

          if (response.ok) {
            const data = await response.json()
           
            localStorage.setItem('userInfo', JSON.stringify(data.portalUserDTO));

            
              
              const { role, organizationGuid } = data.portalUserDTO;
              const isStaff = role === 'staff';
              const isManager = role === 'manager';
              const isSuperAdmin = role === 'superAdmin'
              const isAdmin = role === 'admin'

              if ((isStaff || isManager) && organizationGuid) {
                navigate(`/userboard/${organizationGuid}`)


              } else if (isSuperAdmin || isAdmin) {
                navigate('/organise')

              }
            
          } else {
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('userInfo');
          }
        }

        catch (error) {
          console.log(error)
          setError('An error occurred. Please try again.')
        }
      }
    }

    checkLoginStatus()
  }, [navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);


    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`,

        {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)

          },
          body: JSON.stringify({ email, password, rememberMe }),
          credentials: 'include',
        })

      if (response.ok) {
        const data = await response.json()
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userInfo', JSON.stringify(data));
        if (rememberMe) {
          localStorage.setItem('rememberMe', 'true');

        } else {
          localStorage.removeItem('rememberMe');
        }

        

        const searchParams = new URLSearchParams(location.search);
        const redirectUrl = searchParams.get('redirect');
        
        if (redirectUrl) {
         
          window.location.href = redirectUrl;
        } else {
         
          const { role, organizationGuid } = data;
          if ((role === 'staff' || role === 'manager') && organizationGuid) {
            navigate(`/userboard/${organizationGuid}`);
          } else if (role === 'superAdmin' || role === 'admin') {
            navigate('/organise');
          }
        }


      } else if (response.status === 401) {
        const errorData = await response.json();
        setError("Incorrect email or password. Please try again.");
      } else {
        const errorData = await response.json();
        setError(errorData.message || "An unexpected error occurred. Please try again.");
      }

    } catch (error) {
      console.log(error)
      setError('An error occurred. Please try again.')
    }

    setIsLoading(false)
  }


  return (


    <div className="full_container">
      <div className="container">
        <div className="center verticle_center full_height">
          <div className="login_section">
            <div className="logo_login">
              <div className="center">
                {/* <img className="login-logo" src={logo} alt="Logo" /> */}
                <img className="login-carewindow" width="500" src={carewindow_blue} alt="CareWindow" />
              </div>
            </div>
            <div className="login_form">
              {/* <t className="user-info-text">Please enter your user information.</t> */}
              <form id="loginForm" onSubmit={handleSubmit}>
                <fieldset>
                  <div className="field">
                    <label className="label_field">Email Address </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="E-mail"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="field">
                    <label className="label_field">Password  </label>
                    <input
                      type="password"
                      id="password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="field">
                    <label className="label_field hidden">hidden label</label>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="remember"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />  Remember Me</label>
                    <Link className="forgot" to="/send_email">Forgot Password?</Link>
                  </div>
                  <div className="field margin_0">
                    <label className="label_field hidden">hidden label</label>
                    <button style={{ width: '80px' }} className="btn cur-p btn-primary" type="submit" disabled={isLoading}>
                      Sign in
                    </button>
                  </div>
                </fieldset>
              </form>
              {error && (
                <div className="error-message" style={{ fontWeight: '700px', color: 'red', marginTop: '10px', textAlign: 'center', marginLeft: '60px' }}>
                  {error}
                </div>
              )}
              {isLoading && <div>Loading...</div>}

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Login