import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { generateRandomString } from '../header';

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // First check local storage for faster response
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (isLoggedIn) {
          setIsAuthenticated(true);
          setIsLoading(false);
          return;
        }

        // If not in localStorage, check with the server
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check-login-status`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          
          // Check the actual isAuthenticated value in the response
          if (data.isAuthenticated) {
            // User is authenticated
            if (data.portalUserDTO) {
              localStorage.setItem('userInfo', JSON.stringify(data.portalUserDTO));
            }
            localStorage.setItem('isLoggedIn', 'true');
            setIsAuthenticated(true);
          } else {
            // User is not authenticated despite 200 OK response
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userInfo');
            setIsAuthenticated(false);
          }
        } else {
          // HTTP error response
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('userInfo');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Auth check error:', error);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userInfo');
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  if (isLoading) {
    // Show loading indicator while checking auth status
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Redirect to login with the current URL as a redirect parameter
    return <Navigate to={`/?redirect=${encodeURIComponent(window.location.href)}`} />;
  }

  return children;
}

export default PrivateRoute;