import React, { useState, useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { generateRandomString } from '../header';
import { toast } from "react-hot-toast";
import RefreshIcon from '@mui/icons-material/Refresh'; // 导入刷新图标

const WorkerHistoryTab = ({ user }) => {
  const [workerHistory, setWorkerHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [settingsData, setSettingsData] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshTimerRef = useRef(null);

  useEffect(() => {
    fetchWorkerSignInSettings();
    fetchWorkerHistory();

    // 设置每60秒自动刷新
    refreshTimerRef.current = setInterval(() => {
      fetchWorkerHistory();
    }, 60000);

    // 组件卸载时清理定时器
    return () => {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }
    };
  }, []);

  const fetchWorkerHistory = async () => {
    try {
      setLoading(true);
      setIsRefreshing(true);
      const response = await fetch(
        `${process.env.REACT_APP_WELLNESS_API_URL}/workersignin/${user.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            portalscope: "b3NMWOVzfdRUjrW",
            validatereq: generateRandomString(30),
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setWorkerHistory(data);
      } else {
        console.error("Failed to fetch worker history");
        toast.error("Failed to load worker history");
        setWorkerHistory([]);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to fetch worker history");
      setWorkerHistory([]);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const fetchWorkerSignInSettings = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/get_settings?id=${user.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const settings = await response.json();
        setSettingsData(settings);
        
        // Find the worker sign-in setting
        const workerSignInSetting = settings.find(
          setting => setting.friendlyName === "settings.worker_signin"
        );
        
        if (workerSignInSetting) {
          // Check if the value is a simple "true" or "false" string
          if (workerSignInSetting.value === "true") {
            setIsEnabled(true);
          } else if (workerSignInSetting.value === "false") {
            setIsEnabled(false);
          } else {
            // Default to enabled if value is empty or not recognized
            setIsEnabled(false);
          }
        }
      } else {
        console.error("Failed to fetch settings");
        toast.error("Failed to load worker sign-in settings");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      toast.error("Failed to load worker sign-in settings");
    }
  };

  const formatDateTime = (timeString) => {
    // Add 'Z' to indicate UTC time if not already present
    const utcTimeString = timeString.endsWith('Z') ? timeString : `${timeString}Z`;
    
    // Create date object from the ISO string (now properly treated as UTC)
    const date = new Date(utcTimeString);
    
    // Format the date: "6:45 PM on Feb 17, 2025" in local time
    return `${date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(" ", "")} on ${date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`;
  };

  const handleEnableToggle = async (event) => {
    const newEnabled = event.target.checked;
    await updateWorkerSignInStatus(newEnabled);
  };

  const updateWorkerSignInStatus = async (status) => {
    try {
      // Find the worker sign-in setting
      if (!settingsData) {
        throw new Error("Settings data not available");
      }
      
      const workerSignInSetting = settingsData.find(
        setting => setting.friendlyName === "settings.worker_signin"
      );

      if (!workerSignInSetting) {
        throw new Error("Worker sign-in setting not found");
      }

      // Update the setting with simple boolean value
      const updateResponse = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/update_setting`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          body: JSON.stringify({
            id: workerSignInSetting.id,
            deviceId: user.id,
            name: workerSignInSetting.name,
            friendlyName: workerSignInSetting.friendlyName,
            value: status.toString() // Simple string "true" or "false"
          }),
          credentials: "include",
        }
      );

      if (!updateResponse.ok) {
        throw new Error("Failed to update settings");
      }

      // Update local state
      setIsEnabled(status);
      toast.success(`Worker sign-in ${status ? "enabled" : "disabled"} successfully!`);
      
      // Refresh the worker history
      fetchWorkerHistory();
    } catch (error) {
      console.error("Error updating worker sign-in status:", error);
      toast.error(`Failed to ${status ? "enable" : "disable"} worker sign-in`);
    }
  };

  // 处理手动刷新
  const handleRefresh = () => {
    fetchWorkerHistory();
  };

  return (
    <div style={{ padding: '20px' }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
          paddingBottom: "10px",
          position: "relative"
        }}
      >
        <div className="toggleSwitch" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <input
            type="checkbox"
            id="worker-signin-enabled-toggle"
            checked={isEnabled}
            onChange={handleEnableToggle}
            style={{ display: "none" }}
          />
          <label
            htmlFor="worker-signin-enabled-toggle"
            style={{
              position: "relative",
              display: "inline-block",
              width: "40px",
              height: "20px",
              backgroundColor: isEnabled ? "#4285F4" : "#ccc",
              borderRadius: "20px",
              transition: "all 0.3s",
              cursor: "pointer",
              marginBottom: "0",
            }}
          >
            <span
              style={{
                position: "absolute",
                left: isEnabled ? "22px" : "2px",
                top: "2px",
                width: "16px",
                height: "16px",
                backgroundColor: "white",
                borderRadius: "50%",
                transition: "all 0.3s",
                boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
              }}
            />
          </label>
          <span style={{
            fontSize: "14px",
            fontWeight: "normal",
            color: "#333",
            marginBottom: "0",
          }}>
            Enabled
          </span>
        </div>

        <h3 style={{ 
          margin: 0,
          position: "absolute",
          left: "45%",
          transform: "translateX(-40%)",
          width: "fit-content"
        }}>WORKER HISTORY</h3>
        
        
        <div
        style={{ 
          position: "absolute",
          right: "0px",
        }}>
          
        </div>
      </div>

      <table className="table table-hover">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Date and time</th>
            <th>Name</th>
            <th>Photo (click to enlarge)</th>
            <th><button
            onClick={handleRefresh}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              color: "#4285F4",
              fontSize: "14px",
              padding: "5px",
              borderRadius: "4px",
              transition: "background-color 0.3s",
            }}
            disabled={isRefreshing}
            title="Refresh worker history"
          >
            <RefreshIcon 
              style={{ 
                fontSize: "20px",
                animation: isRefreshing ? "spin 1s linear infinite" : "none"
              }} 
            />
            <style jsx>{`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}</style>
          </button></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="4" className="text-center">
                Loading worker history...
              </td>
            </tr>
          ) : workerHistory.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                No worker history found
              </td>
            </tr>
          ) : (
            workerHistory.map((entry) => (
              <tr key={entry.id}>
                <td style={{ verticalAlign: "middle" }}>{formatDateTime(entry.createdAt)}</td>
                <td style={{ verticalAlign: "middle" }}>{entry.staffName}</td>
                <td>
                  <div 
                    onClick={() => setSelectedImage(entry)}
                    style={{ cursor: 'pointer',verticalAlign: "middle" }}
                  >
                    <img 
                      src={entry.fileName} 
                      alt={entry.staffName}
                      onError={(e) => {
                        e.target.src = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2U5ZTllOSIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmb250LWZhbWlseT0iQXJpYWwiIGZvbnQtc2l6ZT0iMTgiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGRvbWluYW50LWJhc2VsaW5lPSJtaWRkbGUiIGZpbGw9IiM5OTkiPkltYWdlIGZhaWxlZCB0byBsb2FkPC90ZXh0Pjwvc3ZnPg==';
                      }}
                      style={{ 
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        borderRadius: '4px',
                        display: 'block'
                      }}
                    />
                  </div>
                </td>
                <td></td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <Modal
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        aria-labelledby="image-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '60%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '150vh',
          maxHeight: '90vh',
          bgcolor: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* Header with info */}
          <div style={{ 
            padding: '15px 20px',
            
          }}>
            <table className="table" style={{ margin: 0 }}>
              <thead>
                <tr>
                  <th style={{ width: "200px" }}>Date and time</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {selectedImage && (
                  <tr 
                    style={{ 
                      transition: 'background-color 0.3s',
                      borderColor: 'transparent !important' 
                    }}
                    
                  >
                    <td style={{ verticalAlign: "middle" }}>{formatDateTime(selectedImage.createdAt)}</td>
                    <td style={{ verticalAlign: "middle" }}>{selectedImage.staffName}</td>
                    
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Image Container */}
          <div style={{ 
            flex: 1, 
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            maxHeight: 'calc(90vh - 140px)' // Subtract header and footer height
          }}>
            <img
              src={selectedImage?.fileName}
              alt={selectedImage?.staffName}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                display: 'block'
              }}
              onError={(e) => {
                e.target.src = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2U5ZTllOSIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmb250LWZhbWlseT0iQXJpYWwiIGZvbnQtc2l6ZT0iMTgiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGRvbWluYW50LWJhc2VsaW5lPSJtaWRkbGUiIGZpbGw9IiM5OTkiPkltYWdlIGZhaWxlZCB0byBsb2FkPC90ZXh0Pjwvc3ZnPg==';
              }}
            />
          </div>

          {/* Close button */}
          <div style={{ 
            textAlign: 'center', 
            padding: '15px',
            borderTop: '1px solid #E9ECEF'
          }}>
            <button 
              onClick={() => setSelectedImage(null)}
              type='button'
              className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
            >
              Close window
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default WorkerHistoryTab;