import React, { useState, useEffect,useRef } from "react";
import { toast } from "react-hot-toast";
import { generateRandomString } from "../header";
import CheckIcon from "@mui/icons-material/CheckRounded";
import XIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// Import custom feeling images
import feelGoodImg from "../../images/Feel_Good.png";
import feelBadImg from "../../images/Feel_Bad.png";
import RefreshIcon from '@mui/icons-material/Refresh'; 

const CheckIns = ({ user,userInfo }) => {
  const [checkIns, setCheckIns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [checkInTime, setCheckInTime] = useState("06:30 PM");
  const [checkInTimeValue, setCheckInTimeValue] = useState("18:30"); // 24h format for internal use
  const [newCheckInTime, setNewCheckInTime] = useState("18:30"); // 24h format for internal use
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [disableNote, setDisableNote] = useState("");
  const [settingsData, setSettingsData] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshTimerRef = useRef(null);

  useEffect(() => {
    fetchCheckInSettings();
    fetchCheckIns();

    // 设置每60秒自动刷新
    refreshTimerRef.current = setInterval(() => {
      fetchCheckIns();
    }, 60000);

    
    return () => {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }
    };
  }, []);

  const fetchCheckIns = async () => {
    try {
      setLoading(true);
      setIsRefreshing(true);
      const response = await fetch(
        `${process.env.REACT_APP_WELLNESS_API_URL}/wellness/${user.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCheckIns(data);
      } else {
        console.error("Failed to fetch wellness check-ins");
        toast.error("Failed to load check-in history");
        setCheckIns([]);
      }
    } catch (error) {
      console.error("Error fetching wellness check-ins:", error);
      toast.error("Failed to load check-in history");
      setCheckIns([]);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const fetchCheckInSettings = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/get_settings?id=${user.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const settings = await response.json();
        setSettingsData(settings);
        
        // Find the wellness score setting
        const wellnessScoreSetting = settings.find(
          setting => setting.friendlyName === "settings.wellness_score"
        );
        
        if (wellnessScoreSetting) {
          try {
            const wellnessData = JSON.parse(wellnessScoreSetting.value);
            setIsEnabled(wellnessData.status === "true");
            
            // Parse the time (should be a simple HH:MM format)
            if (wellnessData.time) {
              // Use the time directly without timezone conversion
              const timeString = wellnessData.time;
              setCheckInTimeValue(timeString);
              
              // Convert to 12-hour format for display
              const [hours, minutes] = timeString.split(':');
              const hour = parseInt(hours, 10);
              const displayHour = hour % 12 || 12;
              const ampm = hour >= 12 ? 'PM' : 'AM';
              const displayTime = `${displayHour}:${minutes} ${ampm}`;
              
              setCheckInTime(displayTime);
              setNewCheckInTime(timeString);
            }
          } catch (parseError) {
            console.error("Error parsing wellness score settings:", parseError);
            // Use defaults if there's a parsing error
          }
        }
      } else {
        console.error("Failed to fetch settings");
        toast.error("Failed to load check-in settings");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      toast.error("Failed to load check-in settings");
    }
  };

  const formatDateTime = (timeString) => {
    // Add 'Z' to indicate UTC time if not already present
    const utcTimeString = timeString.endsWith('Z') ? timeString : `${timeString}Z`;
    
    // Create date object from the ISO string (now properly treated as UTC)
    const date = new Date(utcTimeString);
    
    // Format the date: "6:45 PM on Feb 17, 2025" in local time
    return `${date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(" ", "")} on ${date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`;
  };

  const handleEnableToggle = async (event) => {
    const newEnabled = event.target.checked;

    if (!newEnabled) {
      // If trying to disable, show modal instead of immediately changing state
      setShowDisableModal(true);
      return;
    }

    // If enabling, proceed normally
    await updateCheckInStatus(true);
  };

  const updateCheckInStatus = async (status, note = "") => {
    // Update the setting
    try {
      // Find the wellness score setting
      if (!settingsData) {
        throw new Error("Settings data not available");
      }
      
      const wellnessScoreSetting = settingsData.find(
        setting => setting.friendlyName === "settings.wellness_score"
      );

      if (!wellnessScoreSetting) {
        throw new Error("Wellness score setting not found");
      }

      // Update the setting with simple time format (no timezone)
      const updateResponse = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/update_setting`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          body: JSON.stringify({
            id: wellnessScoreSetting.id,
            deviceId: user.id,
            name: wellnessScoreSetting.name,
            friendlyName: wellnessScoreSetting.friendlyName,
            value: JSON.stringify({
              status: status.toString(),
              time: checkInTimeValue // Use the 24h format time without timezone
            }),
          }),
          credentials: "include",
        }
      );

      if (!updateResponse.ok) {
        throw new Error("Failed to update settings");
      }
      console.log(userInfo.name);
      // Then add a record to wellness checks
      const noteText = status 
        ? `Admin Panel - ${userInfo.name || "Admin"} enabled check-ins` 
        : `Admin Panel - ${userInfo.name || "Admin"} disabled check-ins with note - ${note}`;

      const wellnessResponse = await fetch(
        `${process.env.REACT_APP_WELLNESS_API_URL}/wellness`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          body: JSON.stringify({
            device_id: user.id,
            score: 0, // Special score for admin actions
            userId: user.id,
            notes: noteText
          }),
          credentials: "include",
        }
      );

      if (!wellnessResponse.ok) {
        throw new Error("Failed to add wellness record");
      }

      // Update local state
      setIsEnabled(status);
      toast.success(`Check-ins ${status ? "enabled" : "disabled"} successfully!`);
      
      // Refresh the check-ins list
      fetchCheckIns();
    } catch (error) {
      console.error("Error updating check-in status:", error);
      toast.error(`Failed to ${status ? "enable" : "disable"} check-ins`);
    }
  };

  const handleDisableConfirm = async () => {
    if (!disableNote.trim()) {
      toast.error("Please enter a reason for disabling check-ins");
      return;
    }
    
    await updateCheckInStatus(false, disableNote);
    setShowDisableModal(false);
    setDisableNote("");
  };

  const handleDisableCancel = () => {
    setShowDisableModal(false);
    setDisableNote("");
  };

  const handleTimeEdit = () => {
    setIsEditingTime(true);
  };

  const handleTimeSave = async () => {
    try {
      if (!settingsData) {
        throw new Error("Settings data not available");
      }
      
      const wellnessScoreSetting = settingsData.find(
        setting => setting.friendlyName === "settings.wellness_score"
      );

      if (!wellnessScoreSetting) {
        throw new Error("Wellness score setting not found");
      }

      // 修复：确保使用当前UI状态的isEnabled值，而不是从数据库解析出的值
      // 这样就不会意外地覆盖当前的启用状态
      
      // Create the 12-hour format display time for the note
      const [hours, minutes] = newCheckInTime.split(':');
      const hour = parseInt(hours, 10);
      const displayHour = hour % 12 || 12;
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const displayTime = `${displayHour}:${minutes}${ampm}`;

      // Update the setting
      const updateResponse = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/update_setting`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          body: JSON.stringify({
            id: wellnessScoreSetting.id,
            deviceId: user.id,
            name: wellnessScoreSetting.name,
            friendlyName: wellnessScoreSetting.friendlyName,
            value: JSON.stringify({
              status: isEnabled.toString(), // 修复：使用当前UI状态的值
              time: newCheckInTime // Use the 24h format without timezone
            }),
          }),
          credentials: "include",
        }
      );

      if (!updateResponse.ok) {
        throw new Error("Failed to update settings");
      }

      // Add a record to wellness checks about time change
      const noteText = `Admin Panel - ${userInfo.name || "Admin"} set up check-ins to ${displayTime}`;

      const wellnessResponse = await fetch(
        `${process.env.REACT_APP_WELLNESS_API_URL}/wellness`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "portalscope": "b3NMWOVzfdRUjrW",
            "validatereq": generateRandomString(30)
          },
          body: JSON.stringify({
            device_id: user.id,
            score: 0, // Special score for admin actions
            userId: user.id,
            notes: noteText
          }),
          credentials: "include",
        }
      );

      if (!wellnessResponse.ok) {
        throw new Error("Failed to add wellness record");
      }

      // Convert to 12-hour format for display
      const hour12 = parseInt(hours, 10) % 12 || 12;
      const amPm = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
      const time12h = `${hour12}:${minutes} ${amPm}`;
      
      setCheckInTime(time12h);
      setCheckInTimeValue(newCheckInTime);
      setIsEditingTime(false);
      toast.success("Check-in time updated successfully!");
      
      // Refresh the check-ins list
      fetchCheckIns();
    } catch (error) {
      console.error("Error updating check-in time:", error);
      toast.error("Failed to update check-in time");
      setNewCheckInTime(checkInTimeValue); // Revert on failure
    }
  };

  const handleCancelEdit = () => {
    setNewCheckInTime(checkInTimeValue);
    setIsEditingTime(false);
  };

  const getNoteDisplay = (checkIn) => {
    // For admin actions (score = 0)
    if (checkIn.score === 0) {
      return checkIn.notes;
    }
    
    // For user feelings (score = 50 or 100)
    if (checkIn.score === 50) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color: "#FF5252",
            borderRadius: "4px",
            padding: "4px 8px",
            display: "inline-flex",
          }}
        >
          <img
            src={feelBadImg}
            alt="Feeling bad"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
            }}
          />
          Feeling bad
        </div>
      );
    } else if (checkIn.score === 100) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color: "#4CAF50",
            borderRadius: "4px",
            padding: "4px 8px",
            display: "inline-flex",
          }}
        >
          <img
            src={feelGoodImg}
            alt="Feeling good"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
            }}
          />
          Feeling OK
        </div>
      );
    }
    
    // For any other scores, show the notes
    return checkIn.notes || "No notes";
  };

  const handleTimeChange = (newValue) => {
    if (newValue) {
      const timeString = newValue.format('HH:mm');
      setNewCheckInTime(timeString);
    }
  };

  const handleRefresh = () => {
    fetchCheckIns();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
          paddingBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0",
            }}
          >
            <div className="toggleSwitch" style={{ marginBottom: "-6px" }}>
              <input
                type="checkbox"
                id="enabled-toggle"
                checked={isEnabled}
                onChange={handleEnableToggle}
                style={{ display: "none" }}
              />
              <label
                htmlFor="enabled-toggle"
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "40px",
                  height: "20px",
                  backgroundColor: isEnabled ? "#4285F4" : "#ccc",
                  borderRadius: "20px",
                  transition: "all 0.3s",
                  cursor: "pointer",
                  marginBottom: "0",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: isEnabled ? "22px" : "2px",
                    top: "2px",
                    width: "16px",
                    height: "16px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    transition: "all 0.3s",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                  }}
                />
              </label>
            </div>
            <span style={{
              fontSize: "14px",
              fontWeight: "normal",
              color: "#333",
              marginBottom: "0",
            }}>
              Enabled
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <label
              style={{
                marginBottom: "4px",
                color: "#666",
                fontSize: "14px",
              }}
            >
              Check-in Time
            </label>

            
            {isEditingTime ? (
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={dayjs(`2024-01-01T${newCheckInTime}`)}
                    onChange={handleTimeChange}
                    views={['hours', 'minutes']}
                    format="hh:mm a"
                    ampm={true}
                    closeOnSelect={true}
                    shouldDisableTime={() => false}
                    slotProps={{
                      textField: {
                        size: "small",
                        style: {
                          width: "120px",
                        }
                      },
                      actionBar: {
                        actions: []
                      }
                    }}
                  />
                </LocalizationProvider>
                <div style={{ display: "flex", gap: "4px" }}>
                  <div
                    onClick={handleTimeSave}
                    style={{
                      cursor: "pointer",
                      color: "#4CAF50",
                    }}
                  >
                    <CheckIcon sx={{ fontSize: "1rem" }} />
                  </div>
                  <div
                    onClick={handleCancelEdit}
                    style={{
                      cursor: "pointer",
                      color: "#FF5252",
                    }}
                  >
                    <XIcon sx={{ fontSize: "1rem" }} />
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <input
                  type="text"
                  value={checkInTime}
                  readOnly
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    fontSize: "14px",
                    padding: "4px 8px",
                    outline: "none",
                    width: "120px",
                    backgroundColor: "#f9f9f9",
                    cursor: "default",
                  }}
                />
                <div
                  onClick={handleTimeEdit}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    color: "#000",
                  }}
                >
                  <EditIcon sx={{ fontSize: "1rem" }} />
                </div>
              </div>
            )}
          </div>
          <h3 style={{ 
            margin: 0,
            position: "absolute",
            left: "45%",
            transform: "translateX(-40%)",
            width: "fit-content"
          }}>CHECK-INS</h3>
            <div style={{ 
            position: "absolute",
            right: "0px",
          }}>
            
          </div>
        </div>
      </div>

      <table className="table table-hover">
        <thead>
          <tr>
            <th style={{ paddingRight: "50px" }}>Date and time</th>
            <th>Note</th>
            <th><button
              onClick={handleRefresh}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                color: "#4285F4",
                fontSize: "14px",
                padding: "5px",
                borderRadius: "4px",
                transition: "background-color 0.3s",
              }}
              disabled={isRefreshing}
              title="Refresh Wellness Check"
            >
              <RefreshIcon 
                style={{ 
                  fontSize: "20px",
                  animation: isRefreshing ? "spin 1s linear infinite" : "none"
                }} 
              />
              <style jsx>{`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}</style>
            </button></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="3" className="text-center">
                Loading check-ins...
              </td>
            </tr>
          ) : checkIns.length === 0 ? (
            <tr>
              <td colSpan="3" className="text-center">
                No check-ins found
              </td>
            </tr>
          ) : (
            checkIns.map((checkIn) => (
              <tr key={checkIn.id}>
                <td style={{ paddingRight: "50px" }}>{formatDateTime(checkIn.createdAt)}</td>
                <td>{getNoteDisplay(checkIn)}</td>
                <td></td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Disable Check-in Modal */}
      {showDisableModal && (
        <div className="modal" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog" role="document" style={{ marginTop: "100px" }}>
            <div className="modal-content" style={{ width: "400px" }}>
              <div className="modal-header">
                <h1 className="modal-title">Add note</h1>
                <button className="close-button" onClick={handleDisableCancel}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>Please record a reason for disabling check-ins.</p>
                <textarea
                  className="form-control"
                  rows="6"
                  value={disableNote}
                  onChange={(e) => setDisableNote(e.target.value)}
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn cur-p btn-secondary"
                  onClick={handleDisableCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn cur-p btn-dark"
                  onClick={handleDisableConfirm}
                  disabled={!disableNote.trim()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckIns;