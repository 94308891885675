import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './js/login';
import Signup from './js/signup';
import Reset from './js/resetpwd';
import Organise from './js/jac';
import SendEmail from './js/send_email';
import Userboard from './js/userboard';
import VideoCallPage from './js/videoCall';
import PremiumSubscriptionPage from './js/Subscription/premiumSubscriptionPage';
import ExtVideoCallPage from './js/VideoCall/ExtVideoCallPage';
import PrivateRoute from './js/Component/PrivateRoute';

import './App.css';
// import { DeviceProvider } from './js/DevicesContext';

function App() {
  return (

    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Login />} />

          <Route path="/signup" element={<Signup />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/send_email" element={<SendEmail />} />
          
          <Route path="/video-call" element={<VideoCallPage />} />
          <Route path="/ext-video-call" element={<ExtVideoCallPage />} />
          <Route path="/subscribe" element={<PremiumSubscriptionPage />} />
          <Route 
            path='/organise' 
            element={
              <PrivateRoute>
                <Organise />
              </PrivateRoute>
            } 
          />
          <Route 
            path='/userboard/:orgGuid' 
            element={
              <PrivateRoute>
                <Userboard />
              </PrivateRoute>
            } 
          />
        </Routes>
      </div>
    </Router>

  );
}

export default App;