export const hasValue = (value) => {
    if (value === "" || value === null || value === undefined) {
        return false;
    }
    return true;
}

export const ProcessAPIError = (error) => {
    if (hasValue(error?.response?.data?.error)) {
        return error.response.data.error;
    }
    return "There is some error processing your request. Please try after some time";
}