import React, { useEffect, useRef, useState } from 'react';

const AdaptiveName = ({ name }) => {
  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState(14);
  const [displayText, setDisplayText] = useState(name);

  useEffect(() => {
    const adjustTextSize = () => {
      if (!textRef.current) return;
      
      setDisplayText(name);
      let currentFontSize = 14;
      setFontSize(currentFontSize);

      
      setTimeout(() => {
        const measureText = () => {
          const element = textRef.current;
          if (!element) return 0;
          return element.scrollWidth;
        };

        
        let width = measureText();
        // console.log("Initial width:", width);

        
        while (width > 170 && currentFontSize > 11) {
          currentFontSize--;
          setFontSize(currentFontSize);
         
          setTimeout(() => {
            width = measureText();
            // console.log("After font size change:", width);
            
           
            if (currentFontSize === 11 && width > 170) {
              let truncatedName = name;
              while (width > 170 && truncatedName.length > 3) {
                truncatedName = truncatedName.slice(0, -1);
                setDisplayText(`${truncatedName}...`);
              
                setTimeout(() => {
                  width = measureText();
                }, 0);
              }
            }
          }, 0);
        }
      }, 0);
    };

    adjustTextSize();
  }, [name]);

  return (
    <span 
      ref={textRef}
      className="name_user"
      style={{ fontSize: `${fontSize}px` }}
    >
      {displayText}
    </span>
  );
};

export default AdaptiveName;