import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const useAccountStatus = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [accountDeleted, setAccountDeleted] = useState(false);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('userInfo');
    navigate('/');
  }, [navigate]);

  const checkLoginStatus = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/check-login-status`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        

        setIsAuthenticated(data.isAuthenticated);
        setAccountDeleted(data.accountDeleted);

        if (data.accountDeleted) {
          handleLogout();
          toast.error("Your account has been deleted. You've been logged out.");
        } else if (!data.isAuthenticated) {
          handleLogout();
          toast.error("You have been logged out. Please log in again.");
        }
      } else {
        console.error('Failed to check login status');
      }
    } catch (error) {
      console.error('Error checking login status:', error);
    }
  }, [handleLogout]);

  useEffect(() => {
    checkLoginStatus();
    const intervalId = setInterval(checkLoginStatus, 30000); 

    return () => clearInterval(intervalId);
  }, [checkLoginStatus]);

  return { isAuthenticated, accountDeleted };
};

export default useAccountStatus;