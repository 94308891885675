import axios from 'axios'

export const GetFeaturesByOrg = async (org_id) => {
    return axios.get(`${process.env.REACT_APP_FEATURES_API_URL}/get_features_by_org/${org_id}`)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export const CreatePaymentSession = async (data) => {
    return axios.post(`${process.env.REACT_APP_FEATURES_API_URL}/create_payment_session`, data)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
} 

export const CheckPaymentStatus = async (data) => {
    return axios.post(`${process.env.REACT_APP_FEATURES_API_URL}/check_payment_status`, data)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
} 